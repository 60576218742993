<script setup lang="ts">
import { ActivityApi } from '~/net/apis/activity_api'
import empty_activity from '~/assets/image/empty/empty_activity@2x.png'

// import active1 from '~/assets/image/icon/active1.png'
// import active2 from '~/assets/image/icon/active2.png'
// import active3 from '~/assets/image/icon/active3.png'

const router = useRouter()
const list = ref<any[]>([])
async function getList() {
  try {
    const { data } = await ActivityApi.activityList({
      pageNum: 0,
      pageSize: 20,
      exportFlag: true,
      queryCondition: {},
      summary: true
    })
    if (data) {
      list.value = data.record
    }
  } catch (e) {}
}
await getList()
function goDetail(item: any) {
  router.push(`/activityDetail/${item.id}`)
}
</script>

<template>
  <div h-screen>
    <BasePullRefreshList
      wrap-class="w-full grid grid-cols-1 gap2 px-4"
      :request-api="ActivityApi.activityList"
      :offset-list="20"
      :is-empty="true"
      :request-params="{
      }"
    >
    <!-- <div v-if="list.length === 0" h-screen flex flex-col items-center justify-center px-4>
      <img h-33 w-50 :src="empty_activity" />
      <div mt-3 text-xs ct-ffffff>各种优惠活动即将上线</div>
    </div>
    <div v-else justify-start p-4>
      <div v-for="(item, index) in list" :key="index" mb-4 rounded-1 overflow-hidden @click="goDetail(item)">
        <ImgWrapper h-48 w-full :src="item.listPicture" placeholder="/merchant_error.png" />
      </div>
    </div> -->
    <template #="{ data }">
      <div rounded-1 overflow-hidden @click="goDetail(data)">
        <ImgWrapper h-48 w-full :src="(data as Activity).listPicture" placeholder="/merchant_error.png" />
      </div>
    </template>
    <template #empty>
      <div h-screen flex flex-col items-center justify-center px-4>
        <img h-33 w-50 :src="empty_activity" />
        <div mt-3 text-xs ct-ffffff>各种优惠活动即将上线</div>
      </div>
    </template>
  </BasePullRefreshList>
  <TheFooter fixed bottom-0 h-50 w-full />
</div>
</template>
